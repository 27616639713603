.leaflet-routing-container, .leaflet-routing-error {
  width: 320px;
  box-sizing: border-box;
  background-color: #fff;
  padding-top: 4px;
  transition: all .2s;
}

.leaflet-control-container .leaflet-routing-container-hide {
  width: 32px;
  height: 32px;
}

.leaflet-routing-container h2 {
  font-size: 14px;
}

.leaflet-routing-container h3 {
  font-size: 12px;
  font-weight: normal;
}

.leaflet-routing-collapsible .leaflet-routing-geocoders {
  margin-top: 20px;
}

.leaflet-routing-alt, .leaflet-routing-geocoders, .leaflet-routing-error {
  max-height: 320px;
  border-bottom: 1px solid #ccc;
  margin-top: 2px;
  margin-bottom: 6px;
  padding: 6px;
  transition: all .2s;
  overflow-y: auto;
}

.leaflet-control-container .leaflet-routing-container-hide .leaflet-routing-alt, .leaflet-control-container .leaflet-routing-container-hide .leaflet-routing-geocoders {
  display: none;
}

.leaflet-bar .leaflet-routing-alt:last-child {
  border-bottom: none;
}

.leaflet-routing-alt-minimized {
  color: #888;
  max-height: 64px;
  cursor: pointer;
  overflow: hidden;
}

.leaflet-routing-alt table {
  border-collapse: collapse;
}

.leaflet-routing-alt tr:hover {
  cursor: pointer;
  background-color: #eee;
}

.leaflet-routing-alt::-webkit-scrollbar {
  width: 8px;
}

.leaflet-routing-alt::-webkit-scrollbar-track {
  background-color: #eee;
  border-radius: 2px;
}

.leaflet-routing-alt::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
}

.leaflet-routing-icon {
  -webkit-background-size: 240px 20px;
  content: "";
  vertical-align: top;
  width: 20px;
  height: 20px;
  background-image: url("leaflet.routing.icons.7edaf2cc.png");
  background-repeat: no-repeat;
  background-size: 240px 20px;
  margin: 0;
  display: inline-block;
}

.leaflet-routing-icon-continue {
  background-position: 0 0;
}

.leaflet-routing-icon-sharp-right {
  background-position: -20px 0;
}

.leaflet-routing-icon-turn-right {
  background-position: -40px 0;
}

.leaflet-routing-icon-bear-right {
  background-position: -60px 0;
}

.leaflet-routing-icon-u-turn {
  background-position: -80px 0;
}

.leaflet-routing-icon-sharp-left {
  background-position: -100px 0;
}

.leaflet-routing-icon-turn-left {
  background-position: -120px 0;
}

.leaflet-routing-icon-bear-left {
  background-position: -140px 0;
}

.leaflet-routing-icon-depart {
  background-position: -160px 0;
}

.leaflet-routing-icon-enter-roundabout {
  background-position: -180px 0;
}

.leaflet-routing-icon-arrive {
  background-position: -200px 0;
}

.leaflet-routing-icon-via {
  background-position: -220px 0;
}

.leaflet-routing-geocoders div {
  padding: 4px 0;
}

.leaflet-routing-geocoders input {
  width: 303px;
  width: calc(100% - 4px);
  border: 1px solid #ccc;
  line-height: 1.67;
}

.leaflet-routing-geocoders button {
  float: right;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 3px 0 0;
  font: bold 18px Lucida Console, Monaco, monospace;
  transition: background-color .2s;
}

.leaflet-routing-add-waypoint:after {
  content: "+";
}

.leaflet-routing-reverse-waypoints:after {
  content: "⇅";
  font-weight: normal;
}

.leaflet-routing-geocoders button:hover {
  background-color: #eee;
}

.leaflet-routing-geocoders input, .leaflet-routing-remove-waypoint, .leaflet-routing-geocoder {
  position: relative;
}

.leaflet-routing-geocoder-result {
  max-height: 0;
  z-index: 1000;
  font: 12px / 1.5 Helvetica Neue, Arial, Helvetica, sans-serif;
  transition: all .5s;
  position: absolute;
  overflow: hidden;
}

.leaflet-routing-geocoder-result table {
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 4px 4px;
}

.leaflet-routing-geocoder-result-open {
  max-height: 800px;
}

.leaflet-routing-geocoder-selected, .leaflet-routing-geocoder-result tr:hover {
  background-color: #eee;
}

.leaflet-routing-geocoder-no-results {
  color: #888;
  font-style: italic;
}

.leaflet-routing-remove-waypoint {
  vertical-align: middle;
  cursor: pointer;
  background-color: #0000;
  display: inline-block;
}

.leaflet-routing-remove-waypoint:after {
  width: 15px;
  height: 1px;
  z-index: 1;
  content: "×";
  text-align: center;
  cursor: pointer;
  color: #ccc;
  background: #fff;
  margin: -16px auto auto;
  padding: 2px 4px 16px 2px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  display: block;
  position: absolute;
  top: 4px;
  bottom: 0;
  right: 1px;
}

.leaflet-routing-remove-waypoint:hover {
  color: #000;
}

.leaflet-routing-instruction-distance {
  width: 48px;
}

.leaflet-routing-collapse-btn {
  color: #ccc;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 6px;
}

.leaflet-routing-collapse-btn:after {
  content: "×";
}

.leaflet-routing-container-hide .leaflet-routing-collapse-btn {
  width: 26px;
  height: 23px;
  background-image: url("routing-icon.3ae5fd50.png");
  display: block;
  position: relative;
  top: 4px;
  left: 4px;
}

.leaflet-routing-container-hide .leaflet-routing-collapse-btn:after {
  content: none;
}

.leaflet-top .leaflet-routing-container.leaflet-routing-container-hide {
  margin-top: 10px !important;
}

.leaflet-right .leaflet-routing-container.leaflet-routing-container-hide {
  margin-right: 10px !important;
}

.leaflet-bottom .leaflet-routing-container.leaflet-routing-container-hide {
  margin-bottom: 10px !important;
}

.leaflet-left .leaflet-routing-container.leaflet-routing-container-hide {
  margin-left: 10px !important;
}

@media only screen and (max-width: 640px) {
  .leaflet-routing-container {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
}

/*# sourceMappingURL=index.db7c5950.css.map */
